<script>
import Box from './Box'
import BranchNode from './BranchNode'
export default {
  model: {
    prop: 'nodes',
    event: 'input',
  },
  props: {
    nodes: { type: Array, required: true },
    isRoot: Boolean,
    selected: Array,
  },
  components: { Box },
  data() {
    return {
      nodeMap: new Map(),
    }
  },
  methods: {
    getNodeCom(node) {
      const attrs = {
        selected: this.selected,
        parent: this.nodes,
        node,
      }
      if (node.isBranch) {
        return {
          com: BranchNode,
          attrs: Object.assign({ title: '分支' }, attrs),
        }
      }
      return {
        com: Box,
        attrs: Object.assign({ title: '节点', content: node.Name }, attrs),
      }
    },
  },
  render() {
    return (
      <div class="node-list-box">
        {this.isRoot && <Box isRoot parent={this.nodes} selected={this.selected} />}
        {this.nodes.map(node => {
          const { attrs, com } = this.getNodeCom(node)
          {
            // console.log(attrs)
          }
          //editNode是由分支组件第一项添加丢上来的(相当于list的Add)，list向上传的是编辑 Node
          return <com key={node._id} {...{ attrs }}></com>
        })}
      </div>
    )
  },
}
</script>
<style lang="scss" scoped>
#designer_canvas {
  position: relative;
  background: #f2f2f2;
  overflow: visible;
  box-shadow: 3px 3px 6px #888;
  z-index: 0;
  .node-item {
    position: absolute;
    border: 1px solid #ccc;
    background: #f2f2f2;
  }
}
// .node-wrap {
// }
</style>
